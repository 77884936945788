import { useStaticQuery, graphql, Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { IconContext } from 'react-icons';
import {
  IoArrowForward,
  IoLogoFacebook,
  IoLogoInstagram,
} from 'react-icons/io5';
import SwiperCore, {
  Autoplay,
  Navigation,
  Keyboard,
  A11y,
  EffectFade,
} from 'swiper';
import 'swiper/components/a11y/a11y.scss';
import 'swiper/components/navigation/navigation.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

import Container from 'components/Container/Container';
import Instagram from 'components/Instagram/Instagram';
import Layout from 'components/Layout';
import OffersCarousel from 'components/OffersCarousel/OffersCarousel';
import PromoGrid from 'components/PromoGrid/PromoGrid';
import Seo from 'components/Seo';
import TitleBrand from 'components/TitleBrand/TitleBrand';

import useCarousel from 'data/useCarousel';

import { btnLink, btnLinkSecondary } from 'styles/components/links.module.scss';
import * as typography from 'styles/components/typography.module.scss';
import * as styles from 'styles/pages/home.module.scss';

import 'styles/overrides/swiper.scss';

// install Swiper modules
SwiperCore.use([Autoplay, Navigation, Keyboard, A11y, EffectFade]);

const query = graphql`
  query {
    allStrapiPromoBlock(sort: { fields: itemIndex, order: ASC }) {
      nodes {
        category {
          id
          slug
        }
        id
        title
        description
        buttonText
        relativePath
        image {
          alternativeText
          caption
          url
          hash
        }
      }
    }
    site {
      siteMetadata {
        title
        fb
        instagram
      }
    }
  }
`;

const mbS = { marginBottom: '0.5rem' };
const promoBlockStyle = { marginBottom: '3rem', maxWidth: '50rem' };
const iconSize = { size: '32px' };

const IndexPage = () => {
  let {
    allStrapiPromoBlock: { nodes: blocks },
    site: { siteMetadata: info },
  } = useStaticQuery(query);
  const { carousel } = useCarousel();
  const { carousel: bestSellers } = useCarousel('/best-sellers');
  return (
    <Layout fullWidth={true}>
      <Seo title="Κοσμήματα, δαχτυλίδια, κολιέ, βραχιόλια, ρολόγια, αξεσουάρ" />
      <div className={styles.sliderContainer}>
        <Swiper
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          speed={500}
          loop={true}
          spaceBetween={0}
          navigation
          keyboard
          scrollbar={{ draggable: true }}
          className="swiper-container-full"
        >
          {blocks.map((block) => (
            <SwiperSlide
              key={block.id}
              className={styles.slide}
              style={{
                backgroundImage: `url(${
                  process.env.GATSBY_API_URL + block.image[0].url
                })`,
              }}
            >
              <Link className={styles.swiperLink} to={block.relativePath}>
                <div className={styles.slideContent}>
                  <h1 className={styles.slideHeader}>{block.title}</h1>
                  <p className={styles.slideDescription}>{block.description}</p>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={styles.socialContainer}>
          <IconContext.Provider value={{ color: '#fff' }}>
            <a
              href={info.fb}
              target="_blank"
              rel="noreferrer"
              className={styles.socialLogo}
              aria-label={`${info.title} facebook page`}
            >
              <IoLogoFacebook size="2rem" />
            </a>
            <a
              href={info.instagram}
              target="_blank"
              rel="noreferrer"
              className={styles.socialLogo}
              aria-label={`${info.title} instagram account`}
            >
              <IoLogoInstagram size="2rem" />
            </a>
          </IconContext.Provider>
        </div>
      </div>
      <Container>
        <OffersCarousel
          subtitle="Προϊόντα που αγαπήσατε"
          title={<TitleBrand text="BEST SELLERS" />}
          products={bestSellers}
        />
      </Container>
      <Container containerClass={styles.promoContainer}>
        <PromoGrid
          big={
            <>
              <Link
                style={mbS}
                className={styles.bigBlockLink}
                to="/daxtylidia/"
              >
                <div className={styles.bigBlockContent}>
                  <h2 className={typography.mainHeaderDark}>ΔΑΧΤΥΛΙΔΙΑ</h2>
                  {/* <p className={typography.mainDescriptionDark}>
                    Επιλέξτε αυτό που σας ταιριάζει
                  </p> */}
                  <IconContext.Provider value={iconSize}>
                    <IoArrowForward className={styles.promoIcon} />
                  </IconContext.Provider>
                </div>
                <StaticImage
                  quality={100}
                  className={styles.smallBlockImg}
                  src="../images/daxtilidia.png"
                  alt="ΔΑΧΤΥΛΙΔΙΑ - Μοναδικές τιμές σε κοσμήματα άριστης ποιότητας"
                />
              </Link>
              <Link className={styles.bigBlockLink} to="/braxiolia/">
                <div className={styles.bigBlockContent}>
                  <h2 className={typography.mainHeaderDark}>ΒΡΑΧΙΟΛΙΑ</h2>
                  {/* <p className={typography.mainDescriptionDark}>
                    Αναδείξτε το στυλ σας με τα πιο statement βραχιόλια
                  </p> */}
                  <IconContext.Provider value={iconSize}>
                    <IoArrowForward className={styles.promoIcon} />
                  </IconContext.Provider>
                </div>
                <StaticImage
                  quality={100}
                  className={styles.smallBlockImg}
                  src="../images/vraxiolia.png"
                  alt="Αναδείξτε το στυλ σας με τα πιο statement βραχιόλια"
                />
              </Link>
            </>
          }
          small={
            <Link className={styles.smallBlockLink} to="/kolie/">
              <div className={styles.smallBlockContent}>
                <h2 className={typography.mainHeaderDark}>ΚΟΛΙΕ</h2>
                {/* <p className={typography.mainDescriptionDark}>
                  Απογειώστε την εμφάνιση σας
                </p> */}
              </div>
              <StaticImage
                quality={100}
                className={styles.smallBlockImg}
                src="../images/kolie.jpg"
                alt="Κολιέ - Μοναδικές τιμές σε κοσμήματα άριστης ποιότητας"
              />
            </Link>
          }
        />
      </Container>
      <div className={styles.blockContentContainer}>
        <div className={styles.gridContainer}>
          <StaticImage
            className={styles.blockImg}
            quality={100}
            layout="fullWidth"
            src="../images/skoularikia.jpg"
            alt="Σκουλαρίκια σε μοντέρνους σχεδιασμούς, κομψά μονόπετρα,
                σκουλαρίκια κρίκοι που ξεχωρίζουν."
          />
          <div className={styles.blockContent}>
            <div>
              <h2 className={typography.mainHeaderDark}>ΣΚΟΥΛΑΡΙΚΙΑ</h2>
              <p className={typography.mainDescriptionDark}>
                Ανακαλύψτε online τις νέες συλλογές από σκουλαρίκια. <br />
                Σκουλαρίκια σε μοντέρνους σχεδιασμούς, κομψά μονόπετρα,
                σκουλαρίκια κρίκοι που ξεχωρίζουν.
                <br />
                Αποκτήστε τα και δώστε λάμψη σε κάθε σας εμφάνιση.
                <br />
              </p>
              <Link className={btnLink} to="/skoylarikia/">
                ΑΝΑΚΑΛΥΨΤΕ ΤΑ
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Container>
        <OffersCarousel
          subtitle="Ότι νεότερο… βρίσκεται εδώ"
          title={<TitleBrand text="ΝΕΕΣ ΠΑΡΑΛΑΒΕΣ" />}
          products={carousel}
          cta={
            <div className={styles.offersCta}>
              <Link className={btnLink} to="/proionta/">
                ΔΕΙΤΕ ΠΕΡΙΣΣΟΤΕΡΑ
              </Link>
            </div>
          }
        />
      </Container>
      <Container containerClass={styles.promoContainer}>
        <PromoGrid
          isLeft={false}
          big={
            <>
              <Link style={mbS} className={styles.bigBlockLink} to="/rologia/">
                <div className={styles.bigBlockContent}>
                  <h2 className={typography.mainHeaderDark}>ΡΟΛΟΓΙΑ</h2>
                  {/* <p className={typography.mainDescriptionDark}>
                    Για κάθε προσωπικότητα και στυλ
                  </p> */}
                  <IconContext.Provider value={iconSize}>
                    <IoArrowForward className={styles.promoIcon} />
                  </IconContext.Provider>
                </div>
                <StaticImage
                  quality={100}
                  className={styles.smallBlockImg}
                  src="../images/rologia.jpg"
                  alt=""
                />
              </Link>
              <Link className={styles.bigBlockLink} to="/a3esoyar/">
                <div className={styles.bigBlockContent}>
                  <h2 className={typography.mainHeaderDark}>ΑΞΕΣΟΥΑΡ</h2>
                  {/* <p className={typography.mainDescriptionDark}>
                    Τσάντες, πορτοφόλια και πολλά άλλα
                  </p> */}
                  <IconContext.Provider value={iconSize}>
                    <IoArrowForward className={styles.promoIcon} />
                  </IconContext.Provider>
                </div>
                <StaticImage
                  quality={100}
                  className={styles.smallBlockImg}
                  src="../images/axesouar.jpg"
                  alt=""
                />
              </Link>
            </>
          }
          small={
            <Link className={styles.smallBlockLink} to="/kolie/mentagion/">
              <div className={styles.smallBlockContent}>
                <h2 className={typography.mainHeaderDark}>ΜΕΝΤΑΓΙΟΝ</h2>
                {/* <p className={typography.mainDescriptionDark}>
                  Μοναδικές τιμές σε κοσμήματα άριστης ποιότητας
                </p> */}
              </div>
              <StaticImage
                quality={100}
                className={styles.smallBlockImg}
                src="../images/mentagion.jpg"
                alt="ΜΕΝΤΑΓΙΟΝ - Μοναδικές τιμές σε κοσμήματα άριστης ποιότητας"
              />
            </Link>
          }
        />
      </Container>
      <div className={styles.blockContentContainerSecondary}>
        <StaticImage
          className={styles.blockImgSecondary}
          quality={100}
          layout="fullWidth"
          src="../images/prosfores.jpg"
          alt=""
        />
        <div className={styles.blockContentCentered}>
          <div>
            <h2 className={styles.mainHeaderAlt}>ΠΡΟΣΦΟΡΕΣ</h2>
            <p className={typography.mainDescription} style={promoBlockStyle}>
              Ανακαλύψτε μοναδικές προσφορές σε όλα μας τα κοσμήματα. Βρείτε
              βραχιόλια, σκουλαρίκια, κολιέ, δαχτυλίδια και αξεσουάρ σε μοντέρνα
              σχέδια και στις καλύτερες τιμές!
            </p>
            <Link className={btnLinkSecondary} to="/prosfores/">
              ΔΕΙΤΕ ΤΙΣ ΠΡΟΣΦΟΡΕΣ
            </Link>
          </div>
        </div>
      </div>
      <Instagram />
    </Layout>
  );
};

export default IndexPage;
