import axios from 'axios';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';

import Container from 'components/Container/Container';
import {
  container,
  header,
  papaki,
  gallery,
  imgWrapper,
  img,
} from 'components/Instagram/Instagram.module.scss';

import { secondaryHeaderDark } from 'styles/components/typography.module.scss';

const hasPosts = (posts) => {
  return posts && posts.length > 0;
};

const Instagram = () => {
  let {
    allInstaNode: { edges: posts },
    site: { siteMetadata: info },
  } = useStaticQuery(query);
  const [postsXHR, setPosts] = useState(null);

  useEffect(() => {
    let axiosCancelSource = axios.CancelToken.source();

    if (!hasPosts(posts)) {
      axios
        .get(
          `https://graph.instagram.com/me/media?fields=id,caption,media_url&limit=5&access_token=${info.instagramAccessToken}`,
          {
            cancelToken: axiosCancelSource.token,
          }
        )
        .then(({ data }) => {
          setPosts(data.data);
        })
        .catch(() => {
          setPosts(null);
        });
    }
    return () => {
      axiosCancelSource.cancel();
    };
  }, [posts, info.instagramAccessToken]);
  if (!hasPosts(posts) && !postsXHR) {
    return null;
  }
  return (
    <section className={container}>
      <h1 className={`${secondaryHeaderDark} ${header}`}>
        <span className={papaki}>@</span>
        {info.instagramLabel}
      </h1>
      <Container>
        <div className={gallery}>
          {hasPosts(posts)
            ? posts.map(({ node: post }) => (
                <a
                  href={`https://instagram.com/p/${post.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={post.id}
                >
                  <GatsbyImage
                    alt={post.caption}
                    image={getImage(post.localFile)}
                  />
                </a>
              ))
            : postsXHR.map((post) => (
                <a
                  href={`https://instagram.com/p/${post.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={post.id}
                  className={imgWrapper}
                >
                  <img
                    src={post.media_url}
                    alt={post.caption}
                    className={img}
                  />
                </a>
              ))}
        </div>
      </Container>
    </section>
  );
};

export default Instagram;

const query = graphql`
  {
    allInstaNode(sort: { fields: timestamp, order: DESC }, limit: 5) {
      edges {
        node {
          id
          caption
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 380, aspectRatio: 1)
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        instagramLabel
        instagramAccessToken
      }
    }
  }
`;
