// extracted by mini-css-extract-plugin
export var sliderContainer = "home-module--slider-container--3xAF9";
export var slide = "home-module--slide--2r2f0";
export var slideContent = "home-module--slide-content--yUPvq";
export var slideHeader = "home-module--slide-header--3nu2i";
export var slideDescription = "home-module--slide-description--3P0UN";
export var swiperLink = "home-module--swiperLink--G-Htc";
export var socialContainer = "home-module--socialContainer--1Lye2";
export var socialLogo = "home-module--socialLogo--3Q8LQ";
export var offersCta = "home-module--offersCta--19Jly";
export var bigBlockContent = "home-module--bigBlockContent--3sH-m";
export var promoIcon = "home-module--promoIcon--3tuna";
export var promoContainer = "home-module--promoContainer--10qWq";
export var bigBlockContentRight = "home-module--bigBlockContentRight--wdKKm";
export var bigBlockLink = "home-module--bigBlockLink--1ixh2";
export var smallBlockImg = "home-module--smallBlockImg--irkRk";
export var move_forward = "home-module--move_forward--1a8U1";
export var smallBlockLink = "home-module--smallBlockLink--bFyJx";
export var smallBlockContent = "home-module--smallBlockContent--3PO-z";
export var blockContentContainer = "home-module--blockContentContainer--3Hqf8";
export var gridContainer = "home-module--gridContainer--2J70E";
export var blockContentContainerSecondary = "home-module--blockContentContainerSecondary--2dYz4";
export var blockContent = "home-module--blockContent--3s0Tl";
export var blockContentCentered = "home-module--blockContentCentered--1WGRp";
export var blockImg = "home-module--blockImg--308H2";
export var blockImgSecondary = "home-module--blockImgSecondary--6diE5";
export var mainHeaderAlt = "home-module--main-header-alt--2G44e";